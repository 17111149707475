



function toggle_background(redirect_url = '') {
    console.log('toggle bckg...');

    const bckg = document.querySelector('#disable_background');
    const body = document.querySelector('body');

    if ( bckg.classList.contains('hidden') ) {
        bckg.classList.remove('hidden');
        body.style.overflow = 'hidden';
    }
    else {
        bckg.classList.add('hidden');
        body.style.overflow = '';
    }

    if (redirect_url !== '')
        window.location = redirect_url;

}


export default {toggle_background}
