// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


import Global from "./global_functions"

document.addEventListener("turbolinks:load", () => {
})

import '../scss/application.scss'
import '../scss/header.scss'
import '../scss/registration.scss'
import '../scss/registration_form.scss'
import '../images/bootcamp_bckg_2.png'
import '../scss/administration.scss'
import '../scss/group.scss'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
//const images = require.context('../images', true)
//const imagePath = (name) => images(name, true)

window.Global = Global;
export {Global}
